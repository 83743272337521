<template>
  <div class="left-sidebar">
    <a-menu mode="inline" class="normal-menu" :defaultOpenKeys="[$store.getters.leftBar[0].id]">
      <template v-for="(item, index) in $store.getters.leftBar">
        <a-menu-item :key="item.id">
          <router-link :to="{name: item.name, params:{id:item.id, name:item.name}}" class="route-link">
            <img :src="item.icon" v-if="item.icon" style="width: 20px; height: 20px;">
            <span class="label">{{ item.name }}</span>
          </router-link>
        </a-menu-item>
      </template>
    </a-menu>

    <!--div class="left-user-info">
      <MemberButton/>

      <div style="padding: 12px 12px 7px 12px;text-align: center">
        <span>剩余次数：</span>
        <span>2</span>
      </div>
    </div>-->
  </div>
</template>
<script>

import store from '@/store'

export default ({
  components: {
    // MemberButton: () => import('@/components/Button/MemberButton.vue')
  },
  data() {
    return {
      current: []
    }
  },
  methods: {},
  mounted() {
    this.$nextTick(() => {
    })

  }
})
</script>

<style scoped lang="scss">

</style>